import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Gift = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M10 5.99951V18.9995M10 5.99951C9.63828 4.50892 9.01545 3.23452 8.21274 2.34262C7.41003 1.45072 6.46469 0.982653 5.5 0.999461C4.83696 0.999461 4.20107 1.26285 3.73223 1.73169C3.26339 2.20053 3 2.83642 3 3.49946C3 4.1625 3.26339 4.79839 3.73223 5.26723C4.20107 5.73607 4.83696 5.99946 5.5 5.99946M10 5.99951C10.3617 4.50892 10.9846 3.23452 11.7873 2.34262C12.59 1.45072 13.5353 0.982653 14.5 0.999461C15.163 0.999461 15.7989 1.26285 16.2678 1.73169C16.7366 2.20053 17 2.83642 17 3.49946C17 4.1625 16.7366 4.79839 16.2678 5.26723C15.7989 5.73607 15.163 5.99946 14.5 5.99946M1 10.9995H19M5 14.9995H5.01M8 14.9995H10M4 5.99951H16C17.6569 5.99951 19 7.34266 19 8.99951V15.9995C19 17.6564 17.6569 18.9995 16 18.9995H4C2.34315 18.9995 1 17.6564 1 15.9995V8.99951C1 7.34266 2.34315 5.99951 4 5.99951Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
