import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useQuery, UseQueryResult } from 'react-query';
import { useFeatureFlags } from '@olo-web/domain/featureFlags';
import { isFeatureEnabledForMerchant } from '@olo-web/utils/common/functions/isFeatureEnabledForMerchant';
import { useSendEvent } from '@olo-web/utils/common/hooks';
import { EAnalyticsEventNames, EGALocations } from '@olo-web/types/enums';
import { isGCPromoValid } from '@olo-web/utils/common/functions/isGCPromoValid';

export const getGiftCardPromo = async (merchantId: string): Promise<IGiftCardPromo> => {
  try {
    const { data } = await axios.get(`/api/giftcardPromo`, {
      params: { merchantId },
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export function useGiftCardPromo(merchantId?: string): UseQueryResult<IGiftCardPromo, AxiosError> {
  const router = useRouter();
  const { merchantId: merchantIdFromUrl } = router.query;
  const merchId = merchantId || merchantIdFromUrl;
  const { data: featureFlags } = useFeatureFlags();
  const { sendEvent } = useSendEvent();

  const giftcardFFEnabled = isFeatureEnabledForMerchant({
    merchantId: merchId as string,
    featureEnabled: featureFlags?.showGiftCardPromo?.on,
    allowList: featureFlags?.showGiftCardPromo?.allow,
    denyList: featureFlags?.showGiftCardPromo?.deny,
  });

  const isEnabled = !!merchId && giftcardFFEnabled;
  return useQuery(['giftCardPromo', merchId], () => getGiftCardPromo(merchId as string), {
    staleTime: 1000000,
    enabled: isEnabled,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const promoValid = isGCPromoValid(data, giftcardFFEnabled);
      if (promoValid) {
        sendEvent(EAnalyticsEventNames.DEFAULT, {
          googleAnalytics: {
            eventInfo: {
              location: EGALocations.ONLINE_ORDER,
              action: 'view',
              object: EAnalyticsEventNames.GIFT_CARD_PROMO_LIVE,
            },
            eventMetadata: {
              giftCardPromoValue: data?.value?.promo_gift_card?.amount,
              giftCardMinimumSpend: data?.value?.minimum_purchase?.amount,
            },
          },
        });
      }
    },
  }) as UseQueryResult<IGiftCardPromo, AxiosError>;
}
