import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const UserCircle = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <circle cx="10" cy="10" r="9" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M10 11C11.6569 11 13 9.65685 13 8C13 6.34315 11.6569 5 10 5C8.34315 5 7 6.34315 7 8C7 9.65685 8.34315 11 10 11Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6259 16.311C15.4304 15.8915 15.1619 15.5051 14.8284 15.1716C14.0782 14.4214 13.0608 14 11.9999 14H7.99994C6.93908 14 5.92166 14.4214 5.17152 15.1716C4.83799 15.5051 4.56944 15.8915 4.37402 16.311"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
