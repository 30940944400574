import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ExclaimCircle = (props: IconProps) => {
  return (
    <Icon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 18.9737C14.9706 18.9737 19 14.9912 19 10.0786C19 5.16604 14.9706 1.18359 10 1.18359C5.02944 1.18359 1 5.16604 1 10.0786C1 14.9912 5.02944 18.9737 10 18.9737Z"
        stroke="#A15D24"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 18.9737C14.9706 18.9737 19 14.9912 19 10.0786C19 5.16604 14.9706 1.18359 10 1.18359C5.02944 1.18359 1 5.16604 1 10.0786C1 14.9912 5.02944 18.9737 10 18.9737Z"
        fill="#A15D24"
      />
      <path
        d="M10 5.13672L10 10.0784"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx="10" cy="14.0318" rx="1" ry="0.988335" fill="white" />
    </Icon>
  );
};
