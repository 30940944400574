import React from 'react';
import { Icon, IconProps, useTheme } from '@chakra-ui/react';

export const GCSmallStars = (props: IconProps) => {
  const { colors } = useTheme();
  return (
    <Icon viewBox="0 0 35 24" fill="none" {...props}>
      <path
        d="M16.9453 11L13.3762 13.6164L9.00185 13.6106L11.5308 17.3032L11.5251 21.8288L15.0943 19.2124L19.4686 19.2183L16.9396 15.5256L16.9453 11Z"
        fill={colors.primary[300]}
      />
      <path
        opacity="0.4"
        d="M16.1328 0L14.6043 1.01512L12.7899 0.926708L13.7711 2.50811L13.6857 4.38523L15.2142 3.37009L17.0285 3.45852L16.0474 1.8771L16.1328 0Z"
        fill={colors.primary[300]}
      />
      <path
        opacity="0.7"
        d="M32.5352 15L29.6001 17.1516L26.0029 17.1468L28.0825 20.1834L28.0778 23.9051L31.013 21.7535L34.6101 21.7583L32.5305 18.7216L32.5352 15Z"
        fill={colors.primary[300]}
      />
      <ellipse
        opacity="0.5"
        rx="1.42281"
        ry="1.38151"
        transform="matrix(-0.250717 0.968061 0.963656 0.267145 2.90817 21.0741)"
        fill={colors.primary[300]}
      />
      <ellipse
        rx="0.711404"
        ry="0.690756"
        transform="matrix(-0.250717 0.968061 0.963656 0.267145 0.842759 4.26091)"
        fill={colors.primary[300]}
      />
      <ellipse
        opacity="0.6"
        rx="1.42281"
        ry="1.38151"
        transform="matrix(-0.250717 0.968061 0.963656 0.267145 26.6855 9.74643)"
        fill={colors.primary[300]}
      />
    </Icon>
  );
};
