import { useEffect } from 'react';
import { useFeatureFlags } from '@domain/featureFlags';
import { useCustomerDispatch, useCustomerState } from '@olo-web/client-state';
import { useToast } from '@olo-web/utils/common/hooks';

export const useShowDisruptionBanner = () => {
  const { data: featureFlags } = useFeatureFlags();
  const { notify, toast } = useToast();
  const disruptionBannerId = 'service-disruption-banner';
  const customerDispatch = useCustomerDispatch();
  const customerState = useCustomerState();
  const hideBanner =
    !featureFlags?.customDisruptionBanner?.show &&
    !featureFlags?.customDisruptionBanner?.statusPage;
  const showBannerBasedOnFF = featureFlags?.customDisruptionBanner?.show;
  const bannerMessage = featureFlags?.customDisruptionBanner?.useDefault
    ? 'We are currently experiencing technical difficulties with our platform and working to resolve them as soon as possible. We apologize for the inconvenience.'
    : featureFlags?.customDisruptionBanner?.message;

  const sendBanner = () => {
    if (!toast.isActive(disruptionBannerId))
      notify({
        description: bannerMessage,
        variant: 'left-accent',
        isClosable: true,
        duration: null,
        status: 'warning',
        id: disruptionBannerId,
      });
  };
  const showBannerBasedOnStatus =
    featureFlags?.customDisruptionBanner?.statusPage && customerState?.statusPageShouldShowBanner;
  useEffect(() => {
    if (
      (showBannerBasedOnFF || showBannerBasedOnStatus) &&
      !customerState?.hasDismissedDisruptionBanner
    ) {
      sendBanner();
      customerDispatch({
        type: 'SET_DISRUPTION',
        payload: { hasDismissedDisruptionBanner: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerState?.hasDismissedDisruptionBanner, showBannerBasedOnStatus, showBannerBasedOnFF]);
  if (hideBanner) return;
  if (showBannerBasedOnFF || showBannerBasedOnStatus) return sendBanner;
  return;
};
