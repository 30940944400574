import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Receipt = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 16 20" fill="none" {...props}>
      <path
        d="M9 13H11M1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H13C13.5304 1 14.0391 1.21071 14.4142 1.58579C14.7893 1.96086 15 2.46957 15 3V19L12 17L10 19L8 17L6 19L4 17L1 19ZM5 5H11H5ZM5 9H11H5Z"
        stroke="#08031D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
