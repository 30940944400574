import React from 'react';
import { Button, ButtonProps, Link, HStack, VStack, Text, Box } from '@chakra-ui/react';
import { GCPromotionStars } from '@olo-web/assets/images/GCPromotionStars.ui';
import { GCSmallStars } from '@olo-web/assets/images/GCSmallStars.ui';
import { isFeatureEnabledForMerchant } from '@olo-web/utils/common/functions/isFeatureEnabledForMerchant';
import { useGiftCardUrl } from '@domain/merchants/hooks/useGiftCardUrl';
import { Gift } from '@olo-web/assets/icons/Gift.ui';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useFeatureFlags } from '@domain/featureFlags';
import { useSendEvent } from '@olo-web/utils/common/hooks';
import { EAnalyticsEventNames, EGALocations } from '@olo-web/types/enums';
import { useGiftCardPromo } from '@olo-web/domain/giftcard/queries/useGiftCardPromo';
import { isGCPromoValid } from '@olo-web/utils/common/functions/isGCPromoValid';

export const PurchaseGiftCardsButton = (props: ButtonProps) => {
  const giftCardUrl = useGiftCardUrl();
  const { data: featureFlags } = useFeatureFlags();
  const { data: merchant } = useMerchant();
  const { sendEvent } = useSendEvent({ canRepeat: true });
  const { data: promoData } = useGiftCardPromo(merchant?.merchantId);
  const promoText = `Buy $${promoData?.value?.minimum_purchase?.amount} get $${promoData?.value?.promo_gift_card?.amount} FREE`;
  const giftcardPromoFFEnabled = isFeatureEnabledForMerchant({
    merchantId: merchant?.merchantId,
    featureEnabled: featureFlags?.showGiftCardPromo?.on,
    allowList: featureFlags?.showGiftCardPromo?.allow,
    denyList: featureFlags?.showGiftCardPromo?.deny,
  });
  const promoValid = isGCPromoValid(promoData, giftcardPromoFFEnabled);
  if (!giftCardUrl) return null;

  const sendGAEvent = () => {
    sendEvent(EAnalyticsEventNames.DEFAULT, {
      googleAnalytics: {
        eventInfo: {
          location: EGALocations.ONLINE_ORDER,
          action: 'click',
          object: EAnalyticsEventNames.GIFT_CARD_CTA,
        },
        eventMetadata: promoValid
          ? {
              giftCardPromoValue: promoData?.value?.promo_gift_card?.amount,
              giftCardMinimumSpend: promoData?.value?.minimum_purchase?.amount,
            }
          : null,
      },
    });
  };

  return (
    <>
      <Link
        isExternal
        href={giftCardUrl}
        w={{ base: '100%', md: 'auto' }}
        _hover={{ textDecoration: 'none' }}
        onClick={sendGAEvent}
      >
        <Button
          as="div"
          variant="ghost"
          colorScheme="blackAlpha"
          data-testid="gift-card-button"
          justifyContent="space-between"
          rightIcon={
            promoValid ? (
              <Gift h={5} w={5} display={{ base: 'undefined', md: 'none' }} />
            ) : (
              <Gift h={5} w={5} />
            )
          }
          w={{ base: '100%', md: 'auto' }}
          {...props}
        >
          {promoValid ? (
            <>
              <HStack h="100%" display={{ base: 'flex', md: 'none' }} id="mobile-giftcard-promo">
                <VStack h="100%">
                  <Text>Purchase gift cards</Text>
                  {promoData?.is_enabled && <Text fontWeight="normal">{promoText}</Text>}
                </VStack>
                {promoData?.is_enabled && <GCPromotionStars w="81px" h="100%" />}
              </HStack>
              <Box display={{ base: 'none', md: 'flex' }} id="desktop-giftcard-promo">
                <VStack>
                  <HStack h="100%" w="100%">
                    <Text>Purchase gift cards</Text>
                    <Gift h={5} w={5} />
                  </HStack>
                  <HStack>
                    {promoData?.is_enabled && <Text fontWeight="normal">{promoText}</Text>}
                    {promoData?.is_enabled && <GCSmallStars h="24px" w="35px" />}
                  </HStack>
                </VStack>
              </Box>
            </>
          ) : (
            'Purchase gift cards'
          )}
        </Button>
      </Link>
    </>
  );
};
