import dayjs from 'dayjs';

export const isGCPromoValid = (
  promoData: IGiftCardPromo | undefined,
  isFFEnabled: boolean
): boolean | undefined => {
  const today = dayjs();
  const promoEndParsed = dayjs(promoData?.duration?.end);
  const promoStartParsed = dayjs(promoData?.duration?.start);
  const promoValid =
    today.diff(promoEndParsed) < 0 && today.diff(promoStartParsed) > 0 && promoData?.is_enabled;

  return promoValid && isFFEnabled;
};
