import React, { useEffect, useMemo } from 'react';
import { useCustomer, useLogout } from '@olo-web/domain/customer';
import {
  Avatar,
  Fade,
  Menu,
  MenuList,
  MenuButton,
  AvatarProps,
  MenuButtonProps,
  Icon,
  Text,
  HStack,
  VStack,
  StackDivider,
  Button,
  ButtonProps,
  MenuItem,
} from '@chakra-ui/react';
import { UserCircle } from '@olo-web/assets/icons/UserCircle.ui';
import { useMerchantPath } from '@domain/merchants/hooks/useMerchantPath';
import { useGetPreviousOrdersFromMerchant } from '@olo-web/domain/orders/queries/useGetPreviousOrdersFromMerchant';
import { useRouter } from 'next/router';
import { useSendEvent, useIsDineIn } from '@olo-web/utils/common/hooks';
import { EAnalyticsEventNames, EOrderTypes } from '@olo-web/types/enums';
import { XCircle } from 'react-feather';
import { Receipt } from '@olo-web/assets/icons/Receipt.ui';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { RewardsIcon } from '@olo-web/assets/icons/RewardsIcon.ui';
import { DisruptionIconButton } from '@olo-web/components/molecules/DisruptionIconButton/DisruptionIconButton';

declare const window: any;

type TUseCustomer = ICustomer & ICustomerInfo & { phone: string };

interface IMenuButton extends ButtonProps {
  icon: any;
}

const commonIconStyles = {
  h: 5,
  w: 5,
  ml: 1,
};

const CustomMenuButton = (props: IMenuButton) => {
  const { icon, children, ...rest } = props;
  return (
    <Button
      p={0}
      color="blackAlpha.900"
      h="44px"
      fontSize="md"
      variant="ghost"
      w="100%"
      rightIcon={!!icon && icon}
      {...rest}
    >
      {children}
    </Button>
  );
};

export function CustomerAvatarMenu({
  avatarProps,
  avatarMenuButtonProps,
}: {
  avatarProps: AvatarProps;
  avatarMenuButtonProps: MenuButtonProps & { 'data-testid'?: string };
  merchant?: IMerchant;
}): JSX.Element {
  const router = useRouter();
  const { data: customer } = useCustomer();
  const { mutate: logOut } = useLogout();
  const { data: history } = useGetPreviousOrdersFromMerchant();
  const { data: merchant } = useMerchant();
  const { sendEvent } = useSendEvent();
  const path = useMerchantPath();
  const isDineIn = useIsDineIn();

  useEffect(() => {
    if (customer?.customerId && window?.FS) {
      const fsCustomerData: Partial<TUseCustomer> = customer;
      delete fsCustomerData.sessionId;
      delete fsCustomerData.sessionSecret;
      window.FS.setUserVars(fsCustomerData);
    }
  }, [customer]);

  const goToOrderHistory = (e) => {
    sendEvent(EAnalyticsEventNames.GO_TO_ORDER_HISTORY, {
      googleAnalytics: {
        eventTarget: e.target,
      },
    });
    router.push(`${path}/history`);
  };

  const goToMyAccount = () => {
    router.push({
      pathname: `${path}/account`,
      query: { ...(isDineIn && { orderType: EOrderTypes.DINE_IN }) },
    });
  };
  const goToMyRewards = () => {
    router.push(`${path}/account#rewards`);
  };

  const name = useMemo(() => {
    return customer?.firstName || undefined;
  }, [customer]);

  return (
    <Fade in={Boolean(customer?.customerId)}>
      <Menu placement="bottom-end" arrowPadding={12}>
        {({ isOpen }) => (
          <>
            <DisruptionIconButton display={{ base: 'inline', md: 'none' }} />
            <MenuButton
              _focus={{ boxShadow: 'outline' }}
              borderRadius="md"
              px={{ base: 0, md: 4 }}
              h="44px"
              _hover={{
                bg: 'blackAlpha.50',
              }}
              {...avatarMenuButtonProps}
              aria-label={`${name} account menu button`}
            >
              <HStack>
                <Text fontSize="sm" fontWeight="semibold">
                  Account
                </Text>
                <Avatar
                  name={name}
                  bg="blackAlpha.350"
                  color="white"
                  h={6}
                  w={6}
                  icon={<Icon as={UserCircle} h={5} w={5} />}
                  {...(isOpen && { 'data-testid': 'user-icon-avatar' })}
                  {...avatarProps}
                />
              </HStack>
            </MenuButton>

            <MenuList zIndex={6} role="menulist" aria-label="double tap screen to close">
              <VStack divider={<StackDivider />}>
                <MenuItem p={0} onClick={goToMyAccount}>
                  <CustomMenuButton icon={<UserCircle {...commonIconStyles} />}>
                    My account
                  </CustomMenuButton>
                </MenuItem>
                {merchant?.spotOnLoyaltyEnabled && !isDineIn && (
                  <MenuItem p={0} onClick={goToMyRewards} data-testid="user-rewards-button">
                    <CustomMenuButton icon={<Icon as={RewardsIcon} {...commonIconStyles} />}>
                      Rewards
                    </CustomMenuButton>
                  </MenuItem>
                )}
                {!isDineIn && (
                  <MenuItem
                    p={0}
                    isDisabled={!history?.length}
                    {...(isOpen && { 'data-testid': 'user-order-history-button' })}
                    onClick={goToOrderHistory}
                  >
                    <CustomMenuButton icon={<Receipt {...commonIconStyles} />}>
                      Order history
                    </CustomMenuButton>
                  </MenuItem>
                )}
                <MenuItem
                  p={0}
                  onClick={logOut}
                  {...(isOpen && { 'data-testid': 'user-sign-out-button' })}
                >
                  <CustomMenuButton icon={<Icon as={XCircle} {...commonIconStyles} />}>
                    Log out
                  </CustomMenuButton>
                </MenuItem>
              </VStack>
            </MenuList>
          </>
        )}
      </Menu>
    </Fade>
  );
}
