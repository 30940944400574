import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const RewardsIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M18 6.00049H2C1.44772 6.00049 1 6.4482 1 7.00049V9.00049C1 9.55277 1.44772 10.0005 2 10.0005H18C18.5523 10.0005 19 9.55277 19 9.00049V7.00049C19 6.4482 18.5523 6.00049 18 6.00049Z" 
        stroke="#08031D" 
        stroke-width="1.5" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
      <path d="M10 6.00049V19.0005" 
        stroke="#08031D" 
        stroke-width="1.5" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
      <path d="M17 10.0005V17.0005C17 17.5309 16.7893 18.0396 16.4142 18.4147C16.0391 18.7898 15.5304 19.0005 15 19.0005H5C4.46957 19.0005 3.96086 18.7898 3.58579 18.4147C3.21071 18.0396 3 17.5309 3 17.0005V10.0005" 
        stroke="#08031D" 
        stroke-width="1.5" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
      <path d="M5.5 6.00044C4.83696 6.00044 4.20107 5.73705 3.73223 5.2682C3.26339 4.79936 3 4.16348 3 3.50044C3 2.8374 3.26339 2.20151 3.73223 1.73267C4.20107 1.26383 4.83696 1.00044 5.5 1.00044C6.46469 0.98363 7.41003 1.4517 8.21274 2.3436C9.01545 3.2355 9.63828 4.50984 10 6.00044C10.3617 4.50984 10.9846 3.2355 11.7873 2.3436C12.59 1.4517 13.5353 0.98363 14.5 1.00044C15.163 1.00044 15.7989 1.26383 16.2678 1.73267C16.7366 2.20151 17 2.8374 17 3.50044C17 4.16348 16.7366 4.79936 16.2678 5.2682C15.7989 5.73705 15.163 6.00044 14.5 6.00044" 
        stroke="#08031D" 
        stroke-width="1.5" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
    </Icon>
  );
};