import React from 'react';
import { Icon, IconProps, useTheme } from '@chakra-ui/react';

export const GCPromotionStars = (props: IconProps) => {
  const { colors } = useTheme();
  return (
    <Icon viewBox="0 0 90 55" fill="none" {...props}>
      <path
        d="M23.5781 15.1714L20.009 17.7878L15.6347 17.7819L18.1636 21.4746L18.1579 26.0002L21.7271 23.3838L26.1014 23.3897L23.5725 19.697L23.5781 15.1714Z"
        fill={colors.primary[300]}
      />
      <path
        d="M53.9492 33L50.3801 35.6164L46.0058 35.6106L48.5347 39.3032L48.529 43.8289L52.0982 41.2124L56.4725 41.2183L53.9435 37.5256L53.9492 33Z"
        fill={colors.primary[300]}
      />
      <path
        opacity="0.4"
        d="M16.1367 0L14.6082 1.01512L12.7939 0.926708L13.775 2.50811L13.6896 4.38523L15.2181 3.37009L17.0324 3.45852L16.0513 1.8771L16.1367 0Z"
        fill={colors.primary[300]}
      />
      <path
        opacity="0.4"
        d="M53.3477 4L51.8191 5.01512L50.0048 4.92671L50.986 6.50811L50.9005 8.38523L52.429 7.37009L54.2434 7.45852L53.2622 5.8771L53.3477 4Z"
        fill={colors.primary[300]}
      />
      <path
        opacity="0.4"
        d="M39.3477 29L37.8191 30.0151L36.0048 29.9267L36.986 31.5081L36.9005 33.3852L38.429 32.3701L40.2434 32.4585L39.2622 30.8771L39.3477 29Z"
        fill={colors.primary[300]}
      />
      <path
        opacity="0.7"
        d="M71.9297 14.854L68.9946 17.0056L65.3974 17.0008L67.4771 20.0374L67.4724 23.7591L70.4075 21.6075L74.0047 21.6123L71.925 18.5756L71.9297 14.854Z"
        fill={colors.primary[300]}
      />
      <path
        opacity="0.7"
        d="M87.5312 45L84.5962 47.1516L80.9989 47.1468L83.0786 50.1834L83.0739 53.9051L86.0091 51.7535L89.6062 51.7583L87.5266 48.7216L87.5312 45Z"
        fill={colors.primary[300]}
      />
      <path
        opacity="0.7"
        d="M15.5312 38L12.5962 40.1516L8.99894 40.1468L11.0786 43.1834L11.0739 46.9051L14.0091 44.7535L17.6062 44.7583L15.5266 41.7216L15.5312 38Z"
        fill={colors.primary[300]}
      />
      <ellipse
        opacity="0.5"
        rx="1.42281"
        ry="1.38151"
        transform="matrix(-0.250717 0.968061 0.963656 0.267145 2.91208 21.0741)"
        fill={colors.primary[300]}
      />
      <ellipse
        opacity="0.5"
        rx="1.42281"
        ry="1.38151"
        transform="matrix(-0.250717 0.968061 0.963656 0.267145 65.6894 47.7464)"
        fill={colors.primary[300]}
      />
      <ellipse
        opacity="0.5"
        rx="1.42281"
        ry="1.38151"
        transform="matrix(-0.250717 0.968061 0.963656 0.267145 84.6894 27.7464)"
        fill={colors.primary[300]}
      />
      <ellipse
        rx="0.711404"
        ry="0.690756"
        transform="matrix(-0.250717 0.968061 0.963656 0.267145 0.846665 4.26091)"
        fill={colors.primary[300]}
      />
      <ellipse
        rx="0.711404"
        ry="0.690756"
        transform="matrix(-0.250717 0.968061 0.963656 0.267145 43.8506 18.8732)"
        fill={colors.primary[300]}
      />
      <ellipse
        rx="0.711404"
        ry="0.690756"
        transform="matrix(-0.250717 0.968061 0.963656 0.267145 82.8428 36.8732)"
        fill={colors.primary[300]}
      />
      <ellipse
        rx="0.711404"
        ry="0.690756"
        transform="matrix(-0.250717 0.968061 0.963656 0.267145 48.8467 53.8732)"
        fill={colors.primary[300]}
      />
      <ellipse
        opacity="0.6"
        rx="1.42281"
        ry="1.38151"
        transform="matrix(-0.250717 0.968061 0.963656 0.267145 35.0683 7.10434)"
        fill={colors.primary[300]}
      />
      <ellipse
        opacity="0.6"
        rx="1.42281"
        ry="1.38151"
        transform="matrix(-0.250717 0.968061 0.963656 0.267145 26.6894 45.7464)"
        fill={colors.primary[300]}
      />
    </Icon>
  );
};
