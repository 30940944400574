import React from 'react';
import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { ExclaimCircle } from '@olo-web/assets/icons/Exclaim_Circle.ui';
import { useShowDisruptionBanner } from '@olo-web/utils/common/hooks/useShowDisruptionBanner';

type DisruptionIconButtonType = Omit<IconButtonProps, 'aria-label'>;

export const DisruptionIconButton = (props?: DisruptionIconButtonType) => {
  const showDisruptionBanner = useShowDisruptionBanner();
  if (showDisruptionBanner)
    return (
      <IconButton
        icon={<ExclaimCircle w={'20px'} h="20px" />}
        px={0}
        onClick={showDisruptionBanner}
        size={{ base: 'sm', md: 'md' }}
        bg="transparent"
        _hover={{ backgroundColor: 'blackAlpha.50' }}
        alignSelf="center"
        borderRadius="100%"
        data-testid="service_disruption_button"
        aria-label="Service disruption warning"
        paddingInlineEnd="0!important"
        paddingInlineStart="0!important"
        {...props}
      />
    );
  return null;
};
